import cookie from 'cookie'
import {findIndex} from 'lodash'
import {ApiConnection} from 'powtoon-api/src/apiConnection.ts'
import {showInPublishFlow} from 'common/utils/publishTargets'

const options = {version: 1}
const csrfToken = process.env.CSRF_TOKEN || cookie.parse(document.cookie)['csrftoken']
export const api = new ApiConnection({csrfToken})

const formatter = new Intl.DateTimeFormat(
  'en-US',
  {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}
)

export const exportDateFormat = date => date ?
  formatter.format(Date.parse(date))
  : false

export function getPresentoonById(id) {
  const path = `presentoon/${id}`
  return api.get(path, options)
}

export function getExportJobs(id) {
  const path = `presentoon/${id}/export`
  return api.get(path, options)
}

export async function getPublishTargets(id) {
  const path = `publish-targets/?powtoon-hashid=${id}`
  const response = await api.get(path, options)
  return Object.values(response)
}

// Gets exports combined with the conversion jobs.
export async function getExportsForTable(id) {
  const publishTargets = await getPublishTargets(id)

  const exports = publishTargets.filter(
    ({category, name, showPublishTarget}) =>
      category !== 'share' &&
      name !== 'duplicate' &&
      showInPublishFlow(showPublishTarget)
  )



  let dbExports

  try {
    dbExports = await getExportJobs(id)
  } catch (e) {
    return exports
  }

  dbExports.forEach(exp => {
    // Format date and add it to export.
    exp.created = exportDateFormat(exp.created)
    exp.finished = exportDateFormat(exp.finished)

    // Special treatment because exports API returns 'wistia_user'
    // if the user exported to wistia
    if (exp.publishTo === 'wistia_user') {
      exp.publishTo = 'wistia'
    }

    // In case the publish target does not exist (turn off) but there is an export.
    const existedExportIndex = findIndex(exports, {name: exp.publishTo})
    if (existedExportIndex !== -1) {
      exports[existedExportIndex] = {
        ...exports[existedExportIndex],
        ...exp
      }
    }
  })

  return exports
}


export function startActivateTrial() {
  const path = 'account/start-trial'
  return api.post(path)
}

export function getPowtoonPublishData(id) {
  const path = `powtoons/${id}/publish-data`
  return api.get(path)
}

export function getAllTags() {
  const path = 'templates/tags/'
  return api.get(path, options)
}
