// @ts-expect-error in import
import JSON_ from 'json_'
import {errorMaker} from 'powtoon-commons/utils/errorBuilders'
import axios, {CancelToken, AxiosRequestConfig, AxiosPromise, Method} from 'axios'
import {URLParser} from 'powtoon-commons/utils/url'
import {ERROR_TYPES} from 'powtoon-commons/logger/consts'

type HTTP_METHOD = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
type RequestBody = object | FormData
const startTime = new Date()

export interface IApiConnectionInjection {
  apiConnection: ApiConnection
}

interface ApiOptions {
  isNextPageUrl?: boolean
  admin?: boolean
  method?: HTTP_METHOD
  useCache?: boolean
  body?: RequestBody
  version?: 1|2
  isFormData?: boolean
  transformResponseToCamelCase?: boolean
  processJsonResponse?: boolean
  includeResponseStatus?: boolean
  contentType?: string,
  legacy_replaceEntireEndpointPath?: boolean,
  captchaResponse?: string,
  licenseGeneration?: number
}

interface ResponseData {
  method: HTTP_METHOD
  url: string
  body?: RequestBody
  headers: Headers
  elapsedSessionSeconds?: number
  responseStatus?: number
  responseStatusText?: string
  responseType?: ResponseType
  responseText?: string
  error?: any
}

interface UploadFilesOptions {
  blob?: Blob | File,
  url?: string,
  externalLibItem?: any,
  onProgressCallback?: (progressEvent: any) => void,
  fileType?: string,
  transformResponseToCamelCase?: boolean,
  filename?: string,
  length?: string,
  cancelLastRequestCB?: (c: any) => void,
  uploadSource?: string,
  isVolatile?: boolean,
  imageType?: string,
  method?: Method,
  id?: string,
  isPPTXImport?: boolean,
  path?: string,
  params?: {[key: string]: string} | object,
  orgLogoUploadRequest?: {logo: File, originalLogo?: File, extraData: string},
  creationFlow?: any
}

export class ApiConnection {

  private readonly apiHost: string
  private readonly cdnApiHost: string
  private readonly cdnPath: string
  private readonly sessionId: string
  private csrfToken: string
  private readonly contentPermissionId: string
  private readonly logger: any
  private readonly licenseGeneration?: number

  constructor(options: {
    apiHost: string, cdnApiHost: string, cdnPath: string,
    sessionId: string, csrfToken: string, contentPermissionId: string,
    licenseGeneration?: number
  }, logger: any = console) {
    const {apiHost, cdnApiHost, cdnPath, sessionId, csrfToken, contentPermissionId, licenseGeneration} = options
    this.apiHost = apiHost || ''
    this.cdnApiHost = cdnApiHost || this.apiHost
    this.cdnPath = cdnPath || ''
    this.sessionId = sessionId
    this.csrfToken = csrfToken
    this.contentPermissionId = contentPermissionId || ''
    this.licenseGeneration = licenseGeneration

    this.logger = logger
  }


  public get<T>(path: string, options: ApiOptions= {}) {
    return this.api<T>(path, {...options, method: 'GET'})
  }

  public post<T>(path: string, body?: RequestBody, options: ApiOptions= {}) {
    return this.api<T>(path, {...options, method: 'POST', body})
  }

  public delete<T>(path: string, options: ApiOptions= {}) {
    return this.api<T>(path, {...options, method: 'DELETE'})
  }

  public put<T>(path: string, body?: RequestBody, options: ApiOptions= {}) {
    return this.api<T>(path, {...options, method: 'PUT', body})
  }

  public patch<T>(path: string, body?: RequestBody, options: ApiOptions= {}) {
    return this.api<T>(path, {...options, method: 'PATCH', body})
  }

  public setCsrfToken(csrfToken: string) {
    this.csrfToken = csrfToken
  }

  private api<T>(path: string, {
    isNextPageUrl= false, admin= false, method= 'GET', useCache= false, body,
    version= 2, isFormData= false, contentType, legacy_replaceEntireEndpointPath = false,
    transformResponseToCamelCase= true, processJsonResponse= true, includeResponseStatus= false,
    captchaResponse
  }: ApiOptions = {}): Promise<T> {
    const isGet = method === 'GET'
    const headers = new Headers()

    // Credentials is needed when:
    const isCredentialsNeeded =
      !this.sessionId && // We don't need credentials in the localhost
      // and if we're not in the localhost, we do want the credentials if
      (!useCache || !isGet) // we don't use cache or we don't do GET request

    if (!isFormData) {
      // we use text/plain for GET requests to prevent wastefull CORS OPTIONS requests
      // see more https://m.alphasights.com/killing-cors-preflight-requests-on-a-react-spa-1f9b04aa5730
      const headersContentType = contentType || (isGet ? 'text/plain' : 'application/json')
      headers.append('Content-Type', headersContentType)
    }

    if (captchaResponse) {
      headers.append('captcha-response', captchaResponse)
    }

    headers.append('Accept', 'application/json')

    if (this.licenseGeneration !== undefined && !isNaN(this.licenseGeneration)) {
      headers.append('x-license-generation', this.licenseGeneration.toString())
    }

    if (this.sessionId && !(useCache && isGet)) {
      headers.append('X-Session', this.sessionId)
    }

    if (this.csrfToken && !isGet) {
      headers.append('X-CSRFToken', this.csrfToken)
    }

    const options: RequestInit = {
      method,
      headers,
      body: (isFormData || typeof body !== 'object') ? body : body && JSON.stringify(body) as any,
      credentials: isCredentialsNeeded ? 'include' : undefined
    }

    const host = isGet && useCache ? this.cdnApiHost : this.apiHost

    const getApiUrl = () => {

      if (admin) {
        return `${host}/admin/api/v${version}/${path}`
      }

      if (legacy_replaceEntireEndpointPath) {
        return `${host}${path}`
      }

      if (isNextPageUrl) {
        // TODO: change this after fix of all api page STDU-???
        return path.includes(host) ? path : `${host}${path}`
      } else {
        return `${host}/api/v${version}/${path}`
      }
    }

    const url = getApiUrl()

    const lastFailedResponse: ResponseData = {url, body, method, headers}
    lastFailedResponse.elapsedSessionSeconds = ((new Date() as any as number) - (startTime as any as number)) / 1000

    return fetch(url, options).then((response: Response) => {
      if (!response.ok) {
        lastFailedResponse.responseStatus = response.status
        lastFailedResponse.responseStatusText = response.statusText
        lastFailedResponse.responseType = response.type
        lastFailedResponse.headers = response.headers

        return response.text().then((text) => {
          lastFailedResponse.responseText = lastFailedResponse.responseStatus === 404 ? 'NA' : text
        }).finally(() => {
          return Promise.reject(response)
        })
      }

      if (response.status === 204) {
        return includeResponseStatus ? Promise.resolve({value: null, status: response.status}) : null
      }

      let responseValuePromise
      if (processJsonResponse) {
        responseValuePromise = transformResponseToCamelCase ?
          response.text().then(snakeJson => JSON_.parse(snakeJson)) :
          response.json()
      } else {
        responseValuePromise = response.text()
      }
      return includeResponseStatus ?
        responseValuePromise.then(value => ({value, status: response.status})) :
        responseValuePromise
    }).catch(e => {

      lastFailedResponse.error = e

      const {errorObj, errorMessage} = errorMaker(e, lastFailedResponse)
      const resourceOrigin = URLParser(lastFailedResponse.url).origin
      this.logger.error(errorObj, {
        ...lastFailedResponse,
        resourceOrigin,
        powtoonErrorMessage: errorMessage,
        area: ERROR_TYPES.FETCH_FAILED
      })

      const rejectError = includeResponseStatus ? {error: errorMessage, status: e.status} : errorObj
      return Promise.reject(rejectError)
    })
  }

  public uploadFiles<T>(
    {
      blob,
      url,
      externalLibItem,
      onProgressCallback,
      fileType,
      transformResponseToCamelCase = true,
      filename,
      length,
      cancelLastRequestCB,
      uploadSource,
      isVolatile,
      imageType,
      method = 'POST',
      id,
      isPPTXImport,
      path,
      params,
      orgLogoUploadRequest,
      creationFlow
    }: UploadFilesOptions
  ): AxiosPromise<T[]> {

    let resultPath: string = ''
    let data: FormData = new FormData()
    const headers: any = {}

    headers['X-CSRFToken'] = this.csrfToken

    if (this.sessionId) {
      headers['X-Session'] = this.sessionId
    }

    if (filename) {
      data.append('filename', filename)
    }

    if (fileType === 'soundtrack' || fileType === 'voiceover') {
      data.append('audio_type', fileType)
      fileType = 'sound'

      // we have the length of a sound when we record it
      if (length) {
        data.append('length', length)
      }
    }

    if (uploadSource) {
      data.append('upload_source', uploadSource)
    }

    if (fileType === 'image' && imageType) {
      data.append('image_type', imageType)
    }

    // binary file from computer
    if (blob) {
      if (isVolatile) {
        const file = new File([blob], filename || '', {type: 'image/jpeg'})
        data.append('file', file)
        resultPath = `volatile/${fileType}s`
      } else if (isPPTXImport) {
        const argsJSON = JSON.stringify({
          file_name: filename,
          target: 'powtoon',
          ...(creationFlow ? {creation_flow: creationFlow} : {})
        })
        data.append('pptx', blob)
        data.append('async', 'true')
        data.append('args', argsJSON)
        resultPath = 'import-pptx/'
      } else {
        data.append('file', blob)
        resultPath = path || `user-media/${fileType}s`
      }
    }

    if (orgLogoUploadRequest) {
      const {logo, originalLogo, extraData} = orgLogoUploadRequest
      if (originalLogo) {
        data.append('original_logo', originalLogo)
      }
      data.append('logo', logo)
      data.append('extra_data', extraData)
      resultPath = 'org/logo'
    }

    // upload from filestack
    if (url) {
      data.append('file_url', url)
      resultPath = `user-media/${fileType}s`
    }

    if (params) {
      Object.entries(params).forEach(([key, value]: [string, string]) => {
        data.append(key, value)
      })
    }

    // upload from external lib item
    if (externalLibItem) {
      const libItemInSnakeCase = JSON_.stringify(externalLibItem)
      data = libItemInSnakeCase
      headers['Content-Type'] = 'application/json'
      resultPath = `media-catalog/providers/${externalLibItem.provider}`
    }

    if ((method === 'PATCH' || method === 'PUT') && id) {
      resultPath = `${resultPath}/${id}`
    }

    const config: AxiosRequestConfig = {
      method,
      url: `${this.apiHost}/api/v2/${resultPath}`,
      headers,
      data,
      transformResponse: (response: any) => transformResponseToCamelCase ?
        JSON_.parse(response) :
        JSON.parse(response)
    }
    
    if (cancelLastRequestCB) {
    // @ts-expect-error CancelToken' only refers to a type, but is being used as a value here.
      config.cancelToken = new CancelToken((c) => cancelLastRequestCB(c))
    }

    if (blob) {
      config.onUploadProgress = onProgressCallback
    }

    return axios(config)
  }
}
